import map from 'lodash/map';
import React, { useCallback, useContext, useMemo } from 'react';

import { setCmdPaletteOpen } from '../../../../shared/foreground/cmdPalette';
import { aboveBelowOrAll } from '../../../../shared/foreground/models';
import { useGlobalTagsAsObject } from '../../../../shared/foreground/stateHooks';
import {
  addTagToAllDocsInList,
  removeTagFromAllDocsInList,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/tag';
import type { GlobalTagsObject } from '../../../../shared/types/tags';
import Tag from '../Tag';
import { PaletteAction } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';
// eslint-disable-next-line import/no-cycle
import { BaseBulkActionProps } from './BulkActionsPalette';

const AddNewTagAction = ({
  focused,
  globalTagsObject,
  listQuery,
}: PaletteAction & Omit<BaseBulkActionProps, 'bulkType'> & { globalTagsObject: GlobalTagsObject }) => {
  const { input } = useContext(CmdInputContext);

  const action = useMemo(
    () => async () => {
      if (input === '') {
        return;
      }
      if (!listQuery) {
        // should have already thrown an error, so safe to just return here
        return;
      }

      await setCmdPaletteOpen(false, { userInteraction: 'unknown' });

      await addTagToAllDocsInList({
        tagName: input,
        listQuery,
        options: { userInteraction: 'unknown' },
      });
    },
    [input, listQuery],
  );

  if (input.toLowerCase() in globalTagsObject) {
    return null;
  }

  return (
    <PaletteAction focused={focused} action={action}>
      Create tag: {input}
    </PaletteAction>
  );
};

const AddExistingTagAction = ({
  focused,
  tagName,
  count,
  listQuery,
}: PaletteAction & Omit<BaseBulkActionProps, 'bulkType'> & { tagName: string; count: number }) => {
  const action = useMemo(
    () => async () => {
      if (!listQuery) {
        // should have already thrown an error, so safe to just return here
        return;
      }

      if (tagName === '') {
        return;
      }

      await setCmdPaletteOpen(false, { userInteraction: 'unknown' });

      await addTagToAllDocsInList({
        tagName,
        listQuery,
        options: { userInteraction: 'unknown' },
      });
    },
    [tagName, listQuery],
  );

  return <PaletteAction focused={focused} action={action}>{`${tagName} (${count})`}</PaletteAction>;
};

export const DeleteTagAction = React.memo(function DeleteTagAction({
  focused,
  tagName,
  listQuery,
  bulkType,
}: PaletteAction & BaseBulkActionProps & { tagName: string }) {
  const action = useMemo(
    () => async () => {
      if (!listQuery) {
        // should have already thrown an error, so safe to just return here
        return;
      }

      await setCmdPaletteOpen(false, { userInteraction: 'unknown' });

      await removeTagFromAllDocsInList({
        tagName,
        listQuery,
        options: { userInteraction: 'unknown' },
      });
    },
    [tagName, listQuery],
  );
  return (
    <PaletteAction focused={focused} action={action}>
      Remove tag from {aboveBelowOrAll(bulkType)}: {tagName}
    </PaletteAction>
  );
});

const ExistingTagsContainer = ({
  tags,
  listQuery,
}: Omit<BaseBulkActionProps, 'bulkType'> & { tags: string[] }) => {
  const deleteTag = useCallback(
    async (tagName: string) => {
      if (!listQuery) {
        // should have already thrown an error, so safe to just return here
        return;
      }

      await setCmdPaletteOpen(false, { userInteraction: 'unknown' });

      await removeTagFromAllDocsInList({
        tagName,
        listQuery,
        options: { userInteraction: 'unknown' },
      });
    },
    [listQuery],
  );

  return (
    <div>
      {tags.map((name) => (
        <Tag key={name} className="tagItem" onClickRemoveIcon={() => deleteTag(name)}>
          {name}
        </Tag>
      ))}
    </div>
  );
};

export const TagsBulkActionsPalette = ({
  listQuery,
  usedTags,
  bulkType,
}: BaseBulkActionProps & { usedTags: string[] }): JSX.Element => {
  const [globalTagsObject] = useGlobalTagsAsObject();

  const customComponent = useMemo(() => {
    if (usedTags.length > 0) {
      return <ExistingTagsContainer listQuery={listQuery} tags={usedTags} />;
    }
  }, [usedTags, listQuery]);

  return (
    <PaletteWrapper
      title="Add Tags"
      placeholder="Start typing your tag name..."
      customComponent={customComponent}
    >
      {usedTags.map((tagName) => {
        return (
          <DeleteTagAction
            key={`remove-${tagName}`}
            focused={false}
            tagName={tagName}
            label={`remove-${tagName}`}
            listQuery={listQuery}
            bulkType={bulkType}
          />
        );
      })}

      {map(globalTagsObject, (tag, key) => {
        return (
          <AddExistingTagAction
            key={key}
            focused={false}
            tagName={tag.name}
            count={tag.totalCount}
            label={key}
            listQuery={listQuery}
          />
        );
      })}

      <AddNewTagAction
        key="new-tag"
        focused={false}
        globalTagsObject={globalTagsObject}
        listQuery={listQuery}
      />
    </PaletteWrapper>
  );
};
