import { SubMenu } from '../../types';
import { updateState } from '../models';

export const openCustomGptSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ManualGptPrompt;
    },
    { eventName: 'cmd-palette-manual-gpt-prompt-sub-menu-opened', userInteraction: 'unknown' },
  );
};
