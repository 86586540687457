import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useMemo } from 'react';

import { globalState } from '../../../../shared/foreground/models';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { getDisplayShortcutKeys } from '../../../../shared/utils/shortcuts';
import { useShortcutsMap } from '../../utils/shortcuts';
import { PaletteAction } from './Base/PaletteAction';
import { PaletteWrapper } from './Base/PaletteWrapper';
import styles from './CommandPalette.module.css';

const ShortcutAction = React.memo(function AddTagMenuAction({
  description,
  key,
  shortcut,
}: PaletteAction & { description: string; key?: string; shortcut: string | string[] }) {
  return (
    <PaletteAction focused={false} key={key} shortcut={shortcut} clickable={false}>
      {description}
    </PaletteAction>
  );
});

export const ShortcutsPalette = (): JSX.Element => {
  const shortcutsMap = useShortcutsMap();
  const keyboardShortcuts = globalState(useCallback((state) => state.keyboardShortcuts, []));
  const sortedShortcuts = useMemo(() => {
    const shortcuts = uniqBy(
      Object.entries(keyboardShortcuts)
        .filter(([, value]) => value.shouldShowInHelp)
        .map(([id, { description, keys }]) => ({ description, id, keys })),
      'description',
    );
    return sortBy(shortcuts, (shortcut) => shortcut.description);
  }, [keyboardShortcuts]);

  return (
    <PaletteWrapper title="Shortcuts" placeholder="Search a command" className={styles.shortcutsPalette}>
      <ShortcutAction
        focused={false}
        label="open Command Palette"
        key="shortcut-cmd-palette"
        description="Open command palette"
        shortcut={shortcutsMap[ShortcutId.OpenCmdPalette]}
      />
      {sortedShortcuts.map(({ description, id, keys }) => {
        if (keys) {
          return (
            <ShortcutAction
              focused={false}
              label={description}
              key={`shortcut-${id}`}
              description={description}
              shortcut={getDisplayShortcutKeys(keys)}
            />
          );
        }

        return null;
      })}
    </PaletteWrapper>
  );
};
