import React from 'react';

import Icon from './Icon';

export default function FeedIcon({
  className = '',
  text = 'Feed',
  width = '16',
  height = '16',
}: { className?: string; text?: string; width?: string; height?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10C2.32608 10 3.59785 10.5268 4.53553 11.4645C5.47322 12.4021 6 13.6739 6 15C6 15.2652 6.10536 15.5196 6.29289 15.7071C6.48043 15.8946 6.73478 16 7 16C7.26522 16 7.51957 15.8946 7.70711 15.7071C7.89464 15.5196 8 15.2652 8 15C8 13.1435 7.2625 11.363 5.94975 10.0503C4.63699 8.7375 2.85652 8 1 8ZM1 4C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6C3.38695 6 5.67613 6.94821 7.36396 8.63604C9.05179 10.3239 10 12.6131 10 15C10 15.2652 10.1054 15.5196 10.2929 15.7071C10.4804 15.8946 10.7348 16 11 16C11.2652 16 11.5196 15.8946 11.7071 15.7071C11.8946 15.5196 12 15.2652 12 15C11.9923 12.084 10.8353 9.28858 8.78 7.22C6.71142 5.16472 3.91601 4.00775 1 4ZM11.61 4.39C8.78879 1.58754 4.97655 0.0101842 1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2C4.44781 2 7.75442 3.36964 10.1924 5.80761C12.6304 8.24558 14 11.5522 14 15C14 15.2652 14.1054 15.5196 14.2929 15.7071C14.4804 15.8946 14.7348 16 15 16C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15C15.9898 11.0235 14.4125 7.21121 11.61 4.39Z"
        />
      </svg>
    </Icon>
  );
}
