import React, { useCallback, useMemo, useState } from 'react';
import { MangoQuery } from 'rxdb';

import { openTagsBulkActionsSubMenu, setCmdPaletteOpen } from '../../../../shared/foreground/cmdPalette';
import { useFind } from '../../../../shared/foreground/databaseHooks';
import {
  aboveBelowOrAll,
  bulkActionTypeBySubmenu,
  globalState,
  groupTitleByBulkType,
  mainTitleTypeByBulkType,
  subMenuTypeByBulkType,
} from '../../../../shared/foreground/models';
import { useDocument, useFocusedDocumentListQuery } from '../../../../shared/foreground/stateHooks';
import { useFocusedDocumentId } from '../../../../shared/foreground/stateHooks/useFocusedDocument';
import {
  deleteAllDocumentsInList,
  markDocumentsAsOpenOrUnopen,
  newMoveAllDocsToNewStatus,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/bulk';
import getUIFriendlyNameForDocumentLocation from '../../../../shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from '../../../../shared/foreground/utils/useDocumentLocations';
import {
  AnyDocument,
  BulkActionType,
  DocumentLocation,
  FirstClassDocument,
  SubMenu,
} from '../../../../shared/types';
import { sliceDocListQuery } from '../../../../shared/utils/sliceDocListQuery';
import { getCurrentDocumentLocationFromPathname } from '../../utils/pathnameHelpers';
import useLocation from '../../utils/useLocation';
import { DeleteAllDocumentsInListDialog } from '../DeleteAllDocumentsInListDialog';
import { PaletteAction, PaletteGroup } from './Base/PaletteAction';
import { PaletteWrapper } from './Base/PaletteWrapper';
// eslint-disable-next-line import/no-cycle
import { DeleteTagAction, TagsBulkActionsPalette } from './TagsBulkActionsPalette';

export interface BaseBulkActionProps {
  listQuery: MangoQuery<AnyDocument> | undefined;
  bulkType: BulkActionType;
}
interface MoveAllDocsActionProps extends BaseBulkActionProps {
  newStatus: DocumentLocation;
}

const MoveAllDocsAction = React.memo(function MoveAllDocsAction({
  focused,
  listQuery,
  newStatus,
  bulkType,
}: PaletteAction & MoveAllDocsActionProps) {
  const action = useMemo(
    () => async () => {
      await setCmdPaletteOpen(false, { userInteraction: 'unknown' });

      await newMoveAllDocsToNewStatus({
        newStatus,
        docQuery: listQuery,
        options: { userInteraction: 'unknown' },
      });
    },
    [newStatus, listQuery],
  );
  return (
    <PaletteAction focused={focused} action={action}>
      Move {aboveBelowOrAll(bulkType)} to {getUIFriendlyNameForDocumentLocation(newStatus)}
    </PaletteAction>
  );
});

interface MarkAllDocumentsAsOpenOrUnopenActionProps extends BaseBulkActionProps {
  markAsOpen: boolean;
}

export const MarkAllDocumentsAsOpenOrUnopenAction = React.memo(
  function MarkAllDocumentsAsOpenOrUnopenAction({
    focused,
    markAsOpen,
    listQuery,
    bulkType,
  }: PaletteAction & MarkAllDocumentsAsOpenOrUnopenActionProps) {
    const action = useMemo(
      () => async () => {
        await setCmdPaletteOpen(false, { userInteraction: 'unknown' });

        await markDocumentsAsOpenOrUnopen({
          docQuery: listQuery,
          markAsOpen,
          options: { userInteraction: 'unknown' },
        });
      },
      [markAsOpen, listQuery],
    );
    return (
      <PaletteAction focused={focused} action={action}>
        Mark {aboveBelowOrAll(bulkType)} as {markAsOpen ? 'seen' : 'unseen'}
      </PaletteAction>
    );
  },
);

const OpenTagsBulkActionsPaletteAction = React.memo(function OpenTagsBulkActionsPaletteAction({
  focused,
  action,
  bulkType,
}: PaletteAction & { action: () => void; bulkType: BulkActionType }) {
  return (
    <PaletteAction focused={focused} action={action}>
      Tag {aboveBelowOrAll(bulkType)} as...
    </PaletteAction>
  );
});

const DeleteAllDocumentsAction = React.memo(function DeleteAllDocumentsAction({
  focused,
  listQuery,
  bulkType,
}: PaletteAction & BaseBulkActionProps) {
  const [showModal, setShowModal] = useState(false);

  const action = useMemo(
    () => async () => {
      await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
      setShowModal(false);

      await deleteAllDocumentsInList({
        docQuery: listQuery,
        options: { userInteraction: 'unknown' },
      });
    },
    [listQuery],
  );

  return (
    <>
      <DeleteAllDocumentsInListDialog
        title={`Deleting ${aboveBelowOrAll(bulkType)} documents`}
        isOpen={showModal}
        onConfirm={action}
        onCancel={() => setShowModal(false)}
      />
      <PaletteAction focused={focused} action={() => setShowModal(true)}>
        Delete {aboveBelowOrAll(bulkType)} documents
      </PaletteAction>
    </>
  );
});

// This should work in:
// - Regular inbox
// - Feeds
// - Filtered views
// - Categories (they are filtered views too)
export const BulkActionsPalette = (): JSX.Element => {
  const focusedDocId = useFocusedDocumentId();
  const subMenu = globalState(useCallback((state) => state.cmdPalette.subMenu, []));

  const location = useLocation();
  const currentDocumentLocation = getCurrentDocumentLocationFromPathname(location.pathname);

  const mangoQuery = useFocusedDocumentListQuery();

  const documentLocations = useDocumentLocations();

  const [focusedDoc] = useDocument<FirstClassDocument>(focusedDocId);

  const docListQueryForBulkType: { [type in BulkActionType]: MangoQuery<AnyDocument> | undefined } =
    useMemo(() => {
      return {
        [BulkActionType.AllDocs]: mangoQuery ?? undefined,
        [BulkActionType.AboveFocusedDoc]:
          focusedDoc && mangoQuery ? sliceDocListQuery(mangoQuery, 'above', focusedDoc) : undefined,
        [BulkActionType.BelowFocusedDoc]:
          focusedDoc && mangoQuery ? sliceDocListQuery(mangoQuery, 'below', focusedDoc) : undefined,
      };
    }, [focusedDoc, mangoQuery]);

  const isBulkActionTagsSubMenu =
    subMenu === SubMenu.BulkActionsTagsAll ||
    subMenu === SubMenu.BulkActionsTagsAbove ||
    subMenu === SubMenu.BulkActionsTagsBelow;

  const [listedDocuments] = useFind('documents', mangoQuery ?? {}, {
    isEnabled: Boolean(mangoQuery) && isBulkActionTagsSubMenu,
  });

  const usedTags = useMemo(() => {
    // TODO: this should really be done more efficiently somehow
    const usedTagsSet = listedDocuments.reduce((acc, d) => {
      if (d.tags) {
        Object.keys(d.tags).forEach((key) => {
          if (d.tags) {
            acc.add(d.tags[key].name);
          }
        });
      }

      return acc;
    }, new Set<string>());
    return Array.from(usedTagsSet);
  }, [listedDocuments]);

  if (isBulkActionTagsSubMenu) {
    return (
      <TagsBulkActionsPalette
        usedTags={usedTags}
        listQuery={docListQueryForBulkType[bulkActionTypeBySubmenu[subMenu]]}
        bulkType={bulkActionTypeBySubmenu[subMenu]}
      />
    );
  }

  return (
    <PaletteWrapper title="Apply bulk actions" placeholder="Start typing...">
      {Object.values(BulkActionType).map((bulkType: BulkActionType) => (
        <PaletteGroup title={groupTitleByBulkType[bulkType]} key={bulkType}>
          {documentLocations
            .filter((documentLocationItem) => documentLocationItem !== currentDocumentLocation)
            .map((documentLocation) => {
              const lowercasedKey = documentLocation.toLowerCase() as DocumentLocation;
              return (
                <MoveAllDocsAction
                  focused={false}
                  key={`move-${aboveBelowOrAll(bulkType)}-doc-${lowercasedKey}`}
                  label={`move ${aboveBelowOrAll(bulkType)} documents ${lowercasedKey}`}
                  tags={[
                    `move ${aboveBelowOrAll(bulkType)}`,
                    lowercasedKey,
                    'triage',
                    getUIFriendlyNameForDocumentLocation(lowercasedKey, false),
                    aboveBelowOrAll(bulkType),
                  ]}
                  listQuery={docListQueryForBulkType[bulkType]}
                  newStatus={documentLocation}
                  mainTitleType={mainTitleTypeByBulkType[bulkType]}
                  bulkType={bulkType}
                />
              );
            })}

          <MarkAllDocumentsAsOpenOrUnopenAction
            focused={false}
            markAsOpen
            label={`mark ${aboveBelowOrAll(bulkType)} documents as seen`}
            tags={[`mark ${aboveBelowOrAll(bulkType)}`, 'seen', 'unseen', aboveBelowOrAll(bulkType)]}
            listQuery={docListQueryForBulkType[bulkType]}
            mainTitleType={mainTitleTypeByBulkType[bulkType]}
            bulkType={bulkType}
          />

          <MarkAllDocumentsAsOpenOrUnopenAction
            focused={false}
            markAsOpen={false}
            label={`mark ${aboveBelowOrAll(bulkType)} documents as unseen`}
            tags={[`mark ${aboveBelowOrAll(bulkType)}`, 'seen', 'unseen', aboveBelowOrAll(bulkType)]}
            listQuery={docListQueryForBulkType[bulkType]}
            mainTitleType={mainTitleTypeByBulkType[bulkType]}
            bulkType={bulkType}
          />

          <OpenTagsBulkActionsPaletteAction
            focused={false}
            label={`tag ${aboveBelowOrAll(bulkType)} documents`}
            tags={[`tag ${aboveBelowOrAll(bulkType)}`, aboveBelowOrAll(bulkType), 'tags']}
            action={() => openTagsBulkActionsSubMenu(subMenuTypeByBulkType[bulkType])}
            mainTitleType={mainTitleTypeByBulkType[bulkType]}
            bulkType={bulkType}
          />

          {usedTags.map((tagName) => {
            return (
              <DeleteTagAction
                key={`remove-${tagName}`}
                focused={false}
                tagName={tagName}
                tags={['delete', aboveBelowOrAll(bulkType), 'remove', 'tag']}
                label={`remove-${aboveBelowOrAll(bulkType)}-${tagName}`}
                listQuery={docListQueryForBulkType[bulkType]}
                mainTitleType={mainTitleTypeByBulkType[bulkType]}
                bulkType={bulkType}
              />
            );
          })}

          <DeleteAllDocumentsAction
            focused={false}
            label="delete all documents"
            tags={['delete', 'remove', aboveBelowOrAll(bulkType)]}
            listQuery={docListQueryForBulkType[bulkType]}
            mainTitleType={mainTitleTypeByBulkType[bulkType]}
            bulkType={bulkType}
          />
        </PaletteGroup>
      ))}
    </PaletteWrapper>
  );
};
