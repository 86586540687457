import createKeyboardShortcutEvent from '../../../web/src/utils/createKeyboardShortcutEvent';
import type { KeyboardShortcut } from '../../types/keyboardShortcuts';
import { globalState } from '../models';

export default function executeKeyboardShortcut(keys: KeyboardShortcut['keys']) {
  const shortcutsFound = Object.values(globalState.getState().keyboardShortcuts).filter(
    (keyboardShortcut) => keyboardShortcut.keys === keys,
  );

  if (!shortcutsFound.length) {
    throw new Error('No such shortcut those keys in state.keyboardShortcuts');
  }

  if (shortcutsFound.length > 1) {
    throw new Error('More than one shortcut with those keys in state.keyboardShortcuts');
  }

  shortcutsFound[0].callback(createKeyboardShortcutEvent('programmatic'));
}
